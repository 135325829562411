import axios from "axios";
import _ from 'lodash';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { authHeader, errorHandler } from "../../api/Api";
import qualification from '../../assests/images/degree.png';
import LOGO from '../../assests/images/LOGO.svg';
import profile from '../../assests/images/profileCandidate.png';
import url from "../../utils/UrlConstant";
import ViewProfile from "./ViewProfile";
import { useParams } from "react-router";
import { Avatar } from '@mui/material';
import styled from 'styled-components';
import ScreenShotModal from "./ScreenShotModal";
const StyledLink = styled(Link)`
  color: #3b489e;
  text-decoration: none;
  position: relative;
  right: 1rem;

  &:hover {
    color: #007bff !important;
  }
`;
const path = window.location.pathname.replace('/candidate-details/', '');
function SharedCandidateDetails(props) {
  const [candidate, setCandidate] = useState({})
  const [results, setResults] = useState([])
  const [examMonitor, setExamMonitor] = useState({});
  const [category, setCategory] = useState([]);
  const [totalResult, setTotalResult] = useState({})
  const [viewProfile, setViewProfile] = useState(false);
  const [pdfData, setPdfData] = useState({})
  const [profilePic, setProfilePic] = useState('');
  const [profilePath, setProfilePath] = useState('');
  const [screenShot, setScreenShot] = useState({});
  const [examId, setExamId] = useState('');
  const [mcqScreenShots, setMcqScreenShots] = useState([]);
  const [screenShotModal, setScreenShotModal] = useState(false)
  const param = useParams();
  const examResultId = param.examResultId;


  useEffect(() => {
    function getExamResult() {
      axios.get(`${url.CANDIDATE_API}/candidate/exam-result/${examResultId}`)
        .then(res => {
          const response = res.data.response
          setExamId(response.examId)
          setResults(response.results)
          setTotalResult(response)
          setCandidate(response?.candidate)
          // getScreenShot(response.examId,response?.candidate?.id)
          axios.get(`${url.CANDIDATE_API}/candidate/exam-monitor/${response?.examId}/${response?.candidate?.email}`)
            .then(response => setExamMonitor(response.data.response));
          axios.get(` ${url.ADMIN_API}/candidate/resume/${res.data.response?.candidate?.id}`, { responseType: 'blob' })
            .then(res => {
              const pdf = {}
              let url = window.URL.createObjectURL(res.data);
              pdf.data = url.concat("#toolbar=0")
              setPdfData(pdf)
            }).catch(e => errorHandler(e))

          axios.get(` ${url.ADMIN_API}/exam/candidate/${response?.examId}`)
            .then(res => setCategory(res.data.response.categories))
            .catch(e => errorHandler(e))

        })
        .catch(error => errorHandler(error))
    }

    getExamResult();
  }, [])

  const setLocalStorage = () => {
    if (!localStorage.getItem(path)) {
      let data = totalResult;
      data['screenShot'] = screenShot
      localStorage.setItem(path, JSON.stringify(data));
    }
    localStorage.setItem(candidate?.id, JSON.stringify(totalResult));
    localStorage.setItem('examMonitor', JSON.stringify(examMonitor));
  }
  const setQuestionForResult = () => {
    localStorage.setItem('question', totalResult.submittedExam[0].question.question)
  }
  const programRender = () => {

    return (
      results?.length > 0 ? _.map(results, (program) => {
        if (program.section === 'PROGRAMMING') {
          return (
            <StyledLink style={{ marginLeft: '0px' }} to={{ pathname: '/admin/result/candidate/programResult/' + totalResult?.id }} onClick={() => setLocalStorage()} target={'_blank'}>View program and Images</StyledLink>
          )
        }
      }) : totalResult.projectRound ? <StyledLink to={{ pathname: `/project-result/${totalResult.id}`, state: { question: totalResult } }} target={'_blank'} onClick={() => setQuestionForResult()} >View Project</StyledLink>
        : '')
  }
  const percentageCal = () => {
    const result = results;
    const question = category;
    const questionsSection = question.filter((qt) => qt.sectionName !== 'PROGRAMMING');
    const totalMark = (_.sumBy(result || [], r => r.totalMarks));
    const totalQuestion = (_.sumBy(questionsSection || [], r => r.totalInSection))
    return ((totalMark / totalQuestion) * 100).toFixed(0);
  }
  const close = () => {
    setViewProfile(false);
  }

  // const getScreenShot = (examId, candidateId) => {
  //   axios.get(`${url.ADMIN_API}/onlineTest/getScreenShot?candidateId=${candidateId}&examId=${examId}`, { headers: authHeader() })
  //     .then(res => {
  //       setProfilePath(res.data.response.profilePath);
  //       setScreenShot(res.data.response);
  //       getMcqQuestionIds(res.data.response)
  //       getProfilePic(res.data.response.profilePath);
  //     })
  //     .catch(er => errorHandler(er));
  // }
  // const getProfilePic = (profilePath) => {

  //   if (screenShot) {
  //     axios.get(`${url.ADMIN_API}/onlineTest/getScreenShotImage?path=${profilePath}`, { headers: authHeader() })
  //       .then(res => {
  //         setProfilePic(res.data.message)
  //       })
  //   }
  // }
  const getMcqQuestionIds = (params) => {
    let questionIds = []
    let mcqScreenShots = []
    _.map(totalResult.submittedExam, res => {
      if (res.question?.questionType === 'MCQ')
        questionIds.push(res.question.id)
    })
    _.map(params?.base64String, r => {

      if (questionIds.includes(r.questionId)) {
        mcqScreenShots.push(r)
        setMcqScreenShots(mcqScreenShots);
      }
    })
  }
  const renderTable = () => {
    return (category?.length > 0 ? _.map(category, (cat) => {
      return (
        results?.length > 0 ? _.map(results, (result) => {
          if (result.section === cat.sectionName && result.section !== 'PROGRAMMING') {
            return (
              <tr className='rowdesign' style={{ paddingLeft: '15px' }}>
                <td style={{ color: '#000000' }}>{result.section}</td>
                <td style={{ color: '#000000', fontWeight: '400', fontSize: '13px' }}>{cat.simple !== 0 ? (((result.easy) / (cat.simple) * 100).toFixed(0)) : 0}</td>
                <td style={{ fontWeight: '400', color: '#000000', fontSize: '13px' }}>{cat.medium !== 0 ? (((result.medium) / (cat.medium) * 100).toFixed(0)) : 0}</td>
                <td style={{ fontWeight: '400', color: '#000000', fontSize: '13' }}>{cat.complex !== 0 ? (((result.hard) / (cat.complex) * 100).toFixed(0)) : 0}</td>
                <td style={{ fontWeight: '400', color: '#000000', fontSize: '13px', paddingLeft: '0px' }}>{cat.totalInSection !== 0 ? (((result.totalMarks) / (cat.totalInSection) * 100).toFixed(0)) : 0}</td>
              </tr>)
          }
        }) : '')
    }) : '')
  }
  const openModal = () => {
    setScreenShotModal(true)
  }
  const screenShotRender = () => {
    return (
      mcqScreenShots?.length > 0 ?
        <StyledLink onClick={() => openModal()} >View MCQ Images</StyledLink>
        : null
    )
  }
  const enlargeImg = () => {
    let img = document.getElementById("img");
    img.style.transform = "scale(2)";
    img.style.transition = "transform 0.25s ease";
    img.style.zIndex = 100;
    document.body.addEventListener("click", closeEnlargedImg);

  }
  const closeEnlargedImg = (event) => {
    let img = document.getElementById("img");
    if (!img.contains(event.target)) {
      img.style.transform = "scale(1)";
      img.style.transition = "none";
      img.style.zIndex = "auto";
      document.body.removeEventListener("click", closeEnlargedImg);
    }
  }
  const onCloseModal = () => {
    setScreenShotModal(false)
  }
  return (
    <div>
      <div className="d-flex" id="wrapper">
        <div id="page-content-wrapper" style={{ position: 'absolute', paddingLeft: '0px' }}>
          <div className='header'>
            <img className='header-logo' src={LOGO} alt="SkillSort" />
          </div>
          <div className="modal-content" style={{ overflowY: 'auto', height: 'calc(100vh - 40px)', paddingRight: '30px' }}>
            <div className="modal-header" style={{ border: 'none' }}>
              <h6 style={{ fontSize: '50px', paddingTop: '5px', fontFamily: 'Baskervville', color: '#000000', paddingLeft: '130px' }}>Candidate Result</h6>
            </div>
            <div className="backPic">
              <div className="modal-body">
                <div>
                  <div style={{ display: "flex" }}>
                    <div className="col-sm-4 user-profile">
                      <div className='row' style={{ display: 'contents' }}>
                        <div className="card-block content-align" >
                          {profilePic ? <Avatar id='img' onClick={enlargeImg} style={{ width: 180, height: 180 }} src={`data:image/png;base64,${profilePic}`} alt="profile" /> :
                            <img src={profile} alt="profile" />
                          }
                          {/* <img src={profile} alt="profile" /> */}
                          <h4 className="f-w-600 mt-4" style={{ fontSize: '1.5rem', marginBottom: '0.5rem', lineHeight: '1.2', color: '#3B489E', fontFamily: 'Montserrat' }}>{candidate.firstName} {candidate.lastName}</h4>
                          <h5 style={{ fontWeight: '400', color: '#000000', fontSize: '13px', marginBottom: '18px' }}><img src={qualification} alt="" style={{ width: '30px', marginLeft: '-36px' }} /> {candidate.qualification}</h5>
                          <p style={{ fontWeight: '400', color: '#000000', fontSize: '13px', marginBottom: '0px' }}>{candidate.email}</p>
                          <p style={{ fontWeight: '400', color: '#000000', fontSize: '13px', marginBottom: '0px' }}>{candidate.phone}</p>
                        </div>
                      </div>
                      <div className='row' style={{ display: 'contents' }}>
                        <div className='card-block content-align' style={{ paddingTop: '0px' }}>
                          <button onClick={() => setViewProfile(!viewProfile)} className='btn btn-nxt' style={{ color: '#FFFFFF', fontWeight: '700px', cursor: 'pointer', paddingBottom: '30px', fontFamily: 'Montserrat' }}>View Resume</button>
                        </div>
                      </div>
                    </div>
                    <div className='verticalline'>
                    </div>
                    <div className="card-block" >
                      <h6 className="m-b-20 p-b-5 b-b-default f-w-600" style={{ border: 'none', color: '#fc3f06' }}>ACADAMIC</h6>
                      <div className="row">
                        <div className="col-sm-2">
                          <p className="m-b-10 f-w-400">SSLC</p>
                          <h6 className="f-w-600">{candidate.sslcPercentage}%</h6>
                        </div>
                        <div className="col-sm-2">
                          <p className="m-b-10 f-w-400">HSC</p>
                          <h6 className="f-w-600">{candidate.hscPercentage}%</h6>
                        </div>
                        <div className="col-sm-2">
                          <p className="m-b-10 f-w-400">UG</p>
                          <h6 className="f-w-600">{candidate.ugPercentage}%</h6>
                        </div>
                        {candidate.pgPercentage ? <div className="col-sm-2">
                          <p className="m-b-10 f-w-400">PG</p>
                          <h6 className="f-w-600">{candidate.pgPercentage}%</h6>
                        </div> : ''}
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-400">Institution</p>
                          <h6 className="f-w-600">{candidate.institution}</h6>
                        </div>
                      </div>
                      {!totalResult.projectRound ? <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600" style={{ border: 'none', color: '#fc3f06', marginBottom: '0px', marginTop: '50px' }}>MCQ ONLINE TEST SCORE</h6> : <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600" style={{ border: 'none', color: '#fc3f06', marginBottom: '0px', marginTop: '50px' }}>PROJECT</h6>}
                      <div className="row" style={{ paddingLeft: '15px', width: '46rem' }}>
                        {!totalResult.projectRound ?
                          <table className="table table-hover" style={{ opacity: '65%', marginBottom: '0px' }}>
                            <thead style={{ backgroundColor: '#E0E1EA' }}>
                              <th className='col-lg-4 thdesign' style={{ fontWeight: '2000', color: '#000000' }}>Section</th>
                              <th className='col-lg-2 thdesign' style={{ fontWeight: '2000', color: '#000000' }}>Simple</th>
                              <th className='col-lg-2 thdesign' style={{ fontWeight: '2000', color: '#000000' }}>Medium</th>
                              <th className='col-lg-2 thdesign' style={{ fontWeight: '2000', color: '#000000' }}>Complex</th>
                              <th className='col-lg-2 thdesign' style={{ fontWeight: '2000', color: '#000000' }}>Total</th>
                            </thead>
                            <tbody>
                              {renderTable()}
                            </tbody>
                          </table> : ''}
                          < div className='row' style={{ width: '710px', marginTop: '5px' }}>
                        <div className='col-7 col-lg-7'>
                          {programRender()}
                          {screenShotRender()}
                        </div>
                        <div className='col-5 col-lg-5'>
                          {!totalResult.projectRound?<p style={{ textAlign: 'center', marginLeft: '145px' }}><strong>{percentageCal()}%</strong></p>:""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      {
    viewProfile ?
      <div>
        <ViewProfile type="resume" pdfData={pdfData.data} onClose={close} />
      </div> : ''
  }
  {
    screenShotModal ?
      <div>
        <ScreenShotModal screenShots={mcqScreenShots} onCloseModal={onCloseModal} />
      </div> : ''
  }

    </div >



  );
}

export default SharedCandidateDetails;

